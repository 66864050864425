import React, { PropsWithChildren } from 'react';
import TabsCollapsibleList from './TabsCollapsibleList';
import TabsContainer from './TabsContainer';
import TabsContent from './TabsContent';
import TabsHeader from './TabsHeader';
import TabsTriggerIconList from './TabsIconList';
import TabsList from './TabsList';
import TabsSection, { TabsShowContent } from './TabsSection';
import TabsTrigger from './TabsTrigger';
import TabsTriggerButton from './TabsTriggerButton';
import TabsTriggerContainer from './TabsTriggerContainer';

export interface Pane<Key = string> {
  icon: React.ElementType;
  secondaryIcon?: React.ReactNode;
  text: string;
  onClose?: () => void;
  key: Key;
  content: React.ReactNode;
}

export type PanelContents = Map<string, React.ReactNode>;

type Orientation = 'vertical' | 'horizontal';

export type ExternalTabsProps<Key> = {
  active: Key;
  onChange: (key: Key) => void;
  contents: PanelContents;
  color?: string;
  orientation?: Orientation;
};

export type ComponentSize = {
  width: number;
  height: number;
};

type InternalTabsProps = {
  triggerListSize?: ComponentSize;
  setTriggerListSize: (size?: ComponentSize) => void;
};

type TabsContextProps<Key> = Omit<ExternalTabsProps<Key>, 'onChange'> &
  InternalTabsProps & {
    onChange: (key: string) => void;
  };

export function serializerTabsContents(panes: Pane[]) {
  return panes.reduce((acc, pane) => {
    acc.set(pane.key, pane.content);
    return acc;
  }, new Map<string, React.ReactNode>());
}

const TabsPanelContext = React.createContext({} as TabsContextProps<string>);

export const useTabsPanelContext = () => {
  const context = React.useContext(TabsPanelContext);
  if (!context) {
    throw new Error(
      'useTabsPanelContext must be used within a TabsPanelProvider',
    );
  }
  return context;
};

const Tabs = <Key extends string>({
  children,
  color = 'bg-base-100',
  active,
  contents,
  onChange,
  orientation = 'horizontal',
}: PropsWithChildren<ExternalTabsProps<Key>>) => {
  const [triggerListSize, setTriggerListSize] = React.useState<ComponentSize>();
  return (
    <TabsPanelContext.Provider
      value={
        {
          color,
          active,
          onChange,
          orientation,
          triggerListSize,
          setTriggerListSize,
          contents,
        } as TabsContextProps<Key>
      }
    >
      {children}
    </TabsPanelContext.Provider>
  );
};

Tabs.List = TabsList;
Tabs.CollapsibleList = TabsCollapsibleList;
Tabs.Section = TabsSection;
Tabs.ShowContent = TabsShowContent;
Tabs.Header = TabsHeader;
Tabs.Content = TabsContent;
Tabs.TriggerButton = TabsTriggerButton;
Tabs.TriggerContainer = TabsTriggerContainer;
Tabs.Trigger = TabsTrigger;
Tabs.Container = TabsContainer;
Tabs.IconsList = TabsTriggerIconList;

export default Tabs;
