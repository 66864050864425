import IconButton from '@/components/common/buttons/IconButton';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import Checkbox from '@/components/common/dataInput/Checkbox';
import LinkText from '@/components/common/LinkText';
import useToggle from '@/data/hook/useToggle';
import { Alert } from '@/models/Alert';
import { accordion } from '@/utils/animations/commom';
import { AnnotationIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import NotificationTag, {
  getAlertTagsStyles,
} from '../../card/NotificationTag';
import {
  DetailTargetProps,
  NotificationActionsProps,
} from '../../card/NotificationTypes';
import i18n from '@/i18n';

type NotificationCardProps = {
  notification: Alert;
  onMarkAsRead: (id: number, hasRead: boolean) => void;
  actor: {
    text: string;
    link?: string;
  };
  actions: React.ReactElement<NotificationActionsProps>;
  detail?: React.ReactElement<DetailTargetProps>;
};

const NotificationCard = ({
  notification,
  onMarkAsRead,
  actor,
  actions,
  detail,
}: NotificationCardProps) => {
  const { isOpen, toggle } = useToggle();

  const { t: tAlertVerbs } = useTranslation('translation', {
    keyPrefix: 'alerts.verbs',
  });

  const { t } = useTranslation('translation');

  const verb = tAlertVerbs(notification.type);

  const isEnglish = i18n.language === 'en';

  const alertAtformattedDate = moment(notification.alertAt)
    .format(
      isEnglish ? 'MM/DD/YYYY [at] hh:mm A' : 'dddd, DD [de] MMM [às] HH:mm',
    )
    .replace(/-feira/, '');

  return (
    <div
      className="p-2 border border-secondary/20 rounded-md shadow-sm w-full flex flex-col gap-1.5"
      data-testid="notificationItem"
    >
      <div
        className={twMerge(
          'w-full flex flex-col gap-1.5',
          notification.isRead ? 'opacity-60' : '',
        )}
      >
        <div className="flex justify-between items-center gap-3">
          <NotificationTag type={notification.type} />
          <div className="flex flex-wrap justify-end text-base-300 text-14">
            <Text text={alertAtformattedDate} />
            <Text text={`(${moment(notification.alertAt).fromNow()})`} />
          </div>
        </div>
        <div className="flex items-center gap-2 justify-between w-full">
          <div className="flex items-center gap-2 w-full">
            <Checkbox
              value={String(notification.isRead)}
              checked={notification.isRead}
              className={{ input: 'h-5 w-5' }}
              onChange={e => onMarkAsRead(notification.id, e.target.checked)}
            />
            <div className="flex flex-wrap gap-1.5">
              <LinkText
                text={actor.text}
                className={twMerge(
                  'leading-none',
                  getAlertTagsStyles(t)[notification.type].className.text,
                )}
                href={actor.link}
              />
              <Text text={verb} className="font-500" />
            </div>
          </div>
          {detail && (
            <IconButton
              testId="expandButton"
              icon={
                <ChevronUpIconWithAnimation
                  className="w-5 h-5 text-primary"
                  animate={{
                    rotate: isOpen ? 180 : 0,
                  }}
                />
              }
              onClick={toggle}
            />
          )}
        </div>

        <ConditionalRenderer condition={notification.comment}>
          <div className="flex gap-1.5 items-start" data-testid="comment">
            <AnnotationIcon className="w-3.5 h-3.5 flex-shrink-0" />
            <Text size="text-14" text={notification.comment} />
          </div>
        </ConditionalRenderer>
      </div>
      {actions}
      <AnimatePresence>
        {isOpen && <motion.div {...accordion}>{detail}</motion.div>}
      </AnimatePresence>
    </div>
  );
};

export default NotificationCard;

const ChevronUpIconWithAnimation = motion(ChevronDownIcon);
