import { BaseFilters } from '@/models/Service';
import requester from '@/axios';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import JsonApiResponse from '@/models/JsonApiResponse';

interface EnrollmentFiltersParams {
  pageSize?: number;
  pageNumber?: number;
  status?: [EnrollmentStatusEnum];
  coursePathSlug?: string;
}

export const listEnrollmentsByStudent = async (
  studentId: number,
  { pageNumber, pageSize, ...rest }: EnrollmentFiltersParams,
) => {
  const { data } = await requester().get<JsonApiResponse<Enrollment>>(
    `students/${studentId}/enrollment/`,
    {
      params: {
        'page[size]': pageSize || 30,
        'page[number]': pageNumber || 1,
        ...rest,
      },
    },
  );
  return data.results;
};

export interface ListEnrollmentsFilters extends BaseFilters {
  klassId?: number;
  status?: EnrollmentStatusEnum[];
  current?: boolean;
}

export const listEnrollments = async (params: ListEnrollmentsFilters = {}) => {
  const { data } = await requester().get<JsonApiResponse<Enrollment>>(
    'students/enrollments/',
    {
      params,
    },
  );
  return data;
};
