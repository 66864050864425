import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment from 'moment';
import { DayPicker, Matcher } from 'react-day-picker';
import { ptBR, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { AnimatePresence, motion } from 'framer-motion';
import { UseFormRegisterReturn } from 'react-hook-form';
import { XIcon } from '@heroicons/react/outline';

import { dropdown, fadeInVariants } from '@/utils/animations/commom';
import { scrollBarYClassName } from '@/utils/scrollBarClassName';
import IconButton from '@/components/common/buttons/IconButton';
import { BaseInputProps } from '@/components/common/dataInput/BaseInput';
import Text from '@/components/common/dataDisplay/Text';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';

type CalendarProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> &
  Omit<BaseInputProps, 'register' | 'value'> & {
    disabledDates?: Matcher[];
    onDatesChange?(values: string[]): void;
    register?: Omit<UseFormRegisterReturn, 'min' | 'max'>;
    value?: string[];
  };

const Calendar = forwardRef<HTMLElement, CalendarProps>(CalendarComponent);

export default Calendar;

function CalendarComponent(
  props: CalendarProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const { value: values = [], disabled, onDatesChange } = props;
  const [t] = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const buildDates = useCallback((dates: string[]) => {
    const currentYearDates = dates.map(date => moment(date));
    return currentYearDates.map(value => value.toDate());
  }, []);

  useEffect(() => setDates(buildDates(values)), [buildDates, values]);

  const [dates, setDates] = useState<Date[]>(buildDates(values));

  const {
    i18n: { language },
  } = useTranslation();

  const locale = language === 'pt_BR' ? ptBR : enUS;

  const onSelect = (dates: Date[]) => {
    setDates(dates);
    onDatesChange?.(
      dates.map(date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')),
    );
  };

  const onDelete = (date: Date) => {
    const filter = dates.filter(selected => selected !== date);

    setDates(filter);

    onDatesChange?.(
      filter.map(date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')),
    );
  };

  return (
    <section className="flex w-full h-fit" ref={ref}>
      <DayPicker
        className={disabled ? 'pointer-events-none select-none' : ''}
        numberOfMonths={12}
        selected={dates}
        mode="multiple"
        locale={locale}
        onSelect={(dates = []) => onSelect(dates)}
        month={moment().set('month', 0).toDate()}
        fixedWeeks
        components={{
          Caption: ({ displayMonth }) => (
            <div className="flex justify-center">
              <span className="capitalize text-primary font-500">
                {moment(displayMonth)
                  .locale(locale.code || 'pt_BR')
                  .format('MMMM')}
              </span>
            </div>
          ),
        }}
        classNames={{
          day_disabled: 'hover:bg-transparent hover:text-neutral',
          head: 'text-primary',
          day: twMerge(
            'text-base-content rounded rounded-full w-full aspect-square transition-all duration-300',
            disabled
              ? ''
              : 'hover:bg-primary-content hover:text-primary focus:bg-base-100',
          ),
          caption: 'capitalize flex flex-row justify-between',
          day_selected:
            'bg-primary-content text-primary focus:bg-primary-content',
          day_today: 'bg-secondary-content text-secondary',
          months: 'grid grid-cols-2 lg:grid-cols-3 h-fit',
        }}
      />

      <AnimatePresence>
        <motion.section
          animate={disabled ? 'hidden' : 'show'}
          variants={fadeInVariants}
          className="flex flex-col gap-1 w-44"
        >
          <Text
            text={t('dates')}
            className="mt-5 bg-transparent text-primary font-500"
          />

          <ul
            className={twMerge(
              'flex flex-col gap-1 pr-4 min-h-8',
              scrollBarYClassName,
            )}
          >
            <ConditionalRenderer
              condition={dates.length}
              fallback={
                <Text text={t('noDates')} className="text-[0.875rem]" />
              }
            >
              {dates.map(date => {
                const formated = moment(date).format('DD/MM/YYYY');

                return (
                  <motion.li
                    {...dropdown}
                    key={formated}
                    className="text-secondary bg-secondary-content rounded-md px-2 py-1 font-rubik font-500 flex 
                  items-center gap-1 text-[0.875rem] w-32 justify-between shadow-sm"
                  >
                    {formated}
                    <IconButton
                      icon={<XIcon className="w-4 cursor-pointer text-error" />}
                      onClick={() => onDelete(date)}
                    />
                  </motion.li>
                );
              })}
            </ConditionalRenderer>
          </ul>
        </motion.section>
      </AnimatePresence>
    </section>
  );
}
