import ActivityPreview from '@/components/activities/ActivityPreview';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import TooltipHandler from '@/components/common/TooltipHandler';
import IconButton from '@/components/common/buttons/IconButton';
import Text from '@/components/common/dataDisplay/Text';
import Modal from '@/components/common/modals/Modal';
import ModalDisable from '@/components/common/modals/ModalDisable';
import useAuth from '@/data/hook/useAuth';
import {
  deleteActivity,
  removeActivityFromLesson,
  updateActivityFromLesson,
} from '@/data/services/activityServices';
import { updateLesson } from '@/data/services/lessonServices';
import { isSuperAdmin } from '@/functions/auth';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import Activity, { ActivityTypeEnum } from '@/models/Activity';
import CoursePath from '@/models/Course';
import { ApiError } from '@/models/Errors';
import { Lesson, LessonUpdateKeyType } from '@/models/Lesson';
import Observable from '@/utils/observers/ObserverPattern';
import alert from '@/utils/UseAlert';
import {
  CodeIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  HandDragging,
  QuizActivityIcon,
  TextActivityIcon,
  TrueOrFalseIcon,
  VideoActivityIcon,
} from '../../icons';
import Tag, { TagProps } from '@/components/common/dataDisplay/Tag';

interface QuestionIconHandlerProps {
  type: ActivityTypeEnum;
}

function QuestionIconHandler({ type }: QuestionIconHandlerProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity',
  });

  switch (type) {
    case ActivityTypeEnum.QUIZ:
      return (
        <React.Fragment>
          <QuizActivityIcon />
          {t('quizLabel')}
        </React.Fragment>
      );
    case ActivityTypeEnum.TEXT:
      return (
        <React.Fragment>
          <TextActivityIcon />
          {t('textLabel')}
        </React.Fragment>
      );
    case ActivityTypeEnum.VIDEO:
      return (
        <React.Fragment>
          <VideoActivityIcon />
          {t('videoLabel')}
        </React.Fragment>
      );
    case ActivityTypeEnum.TRUE_OR_FALSE:
      return (
        <React.Fragment>
          <TrueOrFalseIcon />
          {t('trueFalseLabel')}
        </React.Fragment>
      );
    case ActivityTypeEnum.DND_ORDENATION:
      return (
        <React.Fragment>
          <HandDragging />
          {t('dragAndDropOrdenation')}
        </React.Fragment>
      );
    case ActivityTypeEnum.DND_POSITIONAL:
      return (
        <React.Fragment>
          <HandDragging />
          {t('dragAndDropPositional')}
        </React.Fragment>
      );
    case ActivityTypeEnum.CODE:
      return (
        <React.Fragment>
          <CodeIcon className="w-5 h-5" />
          {t('codeLabel')}
        </React.Fragment>
      );
    default:
      return <React.Fragment />;
  }
}

interface ListActivityItemProps {
  activity: Activity;
  lesson: Lesson;
  coursePath?: CoursePath;
  klassId?: number;
  allowBigChanges: boolean;
  updateActivities: () => void;
  observer?: Observable<number>;
  readOnly?: boolean;
}

function ListActivityItem({
  activity,
  lesson,
  coursePath,
  klassId,
  observer,
  readOnly,
  ...props
}: ListActivityItemProps) {
  const { slugCourseName = '' } = useParams();

  const { allowBigChanges, updateActivities } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity',
  });

  const { user } = useAuth();
  const { t: editRulesT } = useTranslation('translation', {
    keyPrefix: 'adminPage.editCourseRules',
  });
  const key = coursePath?.slug || klassId;
  const readOnlyLesson = readOnly || !key;
  const readOnlyActivity =
    user?.id !== activity.author && !isSuperAdmin(user?.userType);

  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [isVisibleModal, setIsVisibleModal] = useState<'disable' | 'preview'>();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const closeModal = () => setIsVisibleModal(undefined);
  observer?.subscribe({
    id: activity.id,
    action: () => setIsDisabled(true),
  });

  async function handleConfirmDelete() {
    if (selectedActivity !== undefined) {
      setIsRequesting(true);
      await handleDeleteActivity({
        activity: selectedActivity,
        lesson,
      });
      setIsRequesting(false);
    }
    setIsVisibleModal(undefined);
  }
  interface DeleteActivityParams {
    lesson: Lesson;
    activity: Activity;
  }
  async function handleDeleteActivity({
    activity,
    lesson,
  }: DeleteActivityParams) {
    try {
      let lessonId = lesson.id;
      if (isPulished(lesson.status)) {
        const newLesson = await updateLesson({ lessonId, slugCourseName }, {});
        lessonId = newLesson.id;
      }
      if (activity.author === user?.id)
        await deleteActivity({ activityId: activity.id, lessonId });
      else
        await removeActivityFromLesson({ lessonId, activityId: activity.id });
      alert.success(t('deleteSuccess'));
      updateActivities();
    } catch (err: any) {
      const api = new ApiError(err);
      alert.error(api.getErrorMessage() || t('deleteFail'));
    }
  }
  function renderModal(selectedActivity: Activity) {
    setSelectedActivity(selectedActivity);
    setIsVisibleModal('disable');
  }
  const navigate = useNavigate();
  interface GetNewActivityIdParams {
    key: string | number;
    lessonId: number;
    oldActivityId: number;
    type: LessonUpdateKeyType;
  }
  const getNewActivityId = async ({
    lessonId,
    oldActivityId,
  }: GetNewActivityIdParams) => {
    const activity = await updateActivityFromLesson(
      {
        lessonId,
        activityId: oldActivityId,
      },
      {},
    );
    return activity.id;
  };

  const editActivity = async (
    lesson: Lesson,
    key: string | number,
    activity: Activity,
  ) => {
    try {
      const type = typeof key === 'string' ? 'course' : 'class';
      observer?.notifyAll();
      setIsRequesting(true);

      let lessonId = lesson.id;
      let activityId = activity.id;

      if (isPulished(lesson.status)) {
        const newLesson = await updateLesson({ lessonId, slugCourseName }, {});
        lessonId = newLesson.id;
      }
      if (isPulished(activity.status)) {
        activityId = await getNewActivityId({
          key,
          lessonId,
          oldActivityId: activityId,
          type,
        });
      }
      const adminUrl = `/admin/courses/${key}/lessons/${lessonId}/activities/${activityId}`;
      const extraLessonUrl = `/class/${key}/lessons/${lessonId}/activities/${activityId}`;
      const routeUrl = type === 'class' ? extraLessonUrl : adminUrl;
      navigate(routeUrl);
    } catch (error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    } finally {
      setIsRequesting(false);
    }
  };

  return (
    <React.Fragment>
      <div className="activity-list overflow-x-hidden">
        <div className="flex flex-wrap xl:flex-row gap-5 items-center">
          <Text
            size="text-18"
            className="text-base-content h-fit truncate"
            format="rubik-500"
            text={activity.name}
          />

          <div className="flex gap-1 items-center text-primary">
            <ConditionalRenderer condition={readOnlyLesson || readOnlyActivity}>
              <IconButton
                loadingSize="w-6 h-6"
                onClick={() => setIsVisibleModal('preview')}
                icon={<EyeIcon className="w-6 h-6 text-primary" />}
                disabled={isDisabled}
                loading={isRequesting}
              />
            </ConditionalRenderer>
            <ConditionalRenderer condition={!readOnlyLesson}>
              {key && (
                <Fragment>
                  <ConditionalRenderer condition={!readOnlyActivity}>
                    <IconButton
                      testId="editActivityButton"
                      loadingSize="w-6 h-6"
                      onClick={() => editActivity(lesson, key, activity)}
                      icon={
                        <PencilIcon
                          data-testid="pencilIcon"
                          className="w-6 h-6"
                        />
                      }
                      disabled={isDisabled}
                      loading={isRequesting}
                    />
                  </ConditionalRenderer>

                  <TooltipHandler
                    renderTooltip={!allowBigChanges}
                    tooltipMessage={editRulesT('cantModify')}
                  >
                    <button
                      disabled={!allowBigChanges}
                      data-testid="excludeActivity"
                      onClick={() => renderModal(activity)}
                    >
                      <TrashIcon
                        className={`w-6 h-6 ${
                          allowBigChanges ? 'text-error' : 'text-neutral/50'
                        }`}
                      />
                    </button>
                  </TooltipHandler>
                </Fragment>
              )}
            </ConditionalRenderer>
          </div>
          <ActivityBankStatus readOnly={readOnlyLesson || readOnlyActivity} />
        </div>

        <div className="flex flex-col gap-1 pl-2 mt-3">
          {activity.questions.map(({ type }, index) => (
            <span
              className="text-16 flex items-center gap-4 activity-list-item"
              key={index}
            >
              <QuestionIconHandler type={type} />
            </span>
          ))}
        </div>
      </div>
      <ModalDisable
        visible={isVisibleModal === 'disable'}
        selectedObjectName={selectedActivity?.name}
        isRequesting={isRequesting}
        modalType="delete"
        translationString="modalDisableActivity"
        onClickConfirm={() => handleConfirmDelete()}
        onClickCancel={closeModal}
      />
      <Modal
        visible={isVisibleModal === 'preview'}
        onClose={closeModal}
        className="max-w-[60vw]"
      >
        <ConditionalRenderer condition={isVisibleModal === 'preview'}>
          <ActivityPreview activity={activity} />
        </ConditionalRenderer>
      </Modal>
    </React.Fragment>
  );
}

export default ListActivityItem;

type ActivityBankStatusProps = {
  readOnly: boolean;
};

function ActivityBankStatus({ readOnly }: ActivityBankStatusProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'activity',
  });

  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const tags: TagProps[] = [
    { text: t('activityBank'), color: 'accent', testId: 'activityBankTag' },
    { text: tCommon('notEditable'), color: 'warning', testId: 'readOnlyTag' },
  ];

  return (
    <ConditionalRenderer condition={readOnly}>
      <div className="flex flex-wrap gap-2">
        {tags?.map(tag => (
          <Tag key={tag.text} {...tag} />
        ))}
      </div>
    </ConditionalRenderer>
  );
}
