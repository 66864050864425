export enum AlertTypeEnum {
  ABSENCE = 'ABSENCE',
  LATENESS = 'LATENESS',
}
export type Alert = {
  id: number;
  contentType: string;
  isRead: boolean;
  readBy: number;
  readAt: string;
  targetId: number;
  actor: {
    id: number;
    title: string;
  };
  alertAt: string;
  comment: string;
  type: AlertTypeEnum;
};

export type AlertDTO = Pick<Alert, 'targetId' | 'type' | 'alertAt'> & {
  actorId: number;
};

export enum AbsenceEventTypeEnum {
  NEW_ALERT = 'NEW_ALERT',
}

export type NewAlertEvent = {
  alert: Alert;
  event: AbsenceEventTypeEnum.NEW_ALERT;
};

export type AlertEvent = NewAlertEvent;
