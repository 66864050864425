import DNDPositionalActivityProgress from '@/models/DNDPositionalActivityProgress';
import i18n from '../i18n';
import { ActivityTypeEnum } from '../models/Activity';
import {
  AttemptAnswer,
  IQuestionProgress,
  QuizActivityProgress,
  TextActivityProgress,
  TrueOrFalseActivityProgress,
  VideoActivityProgress,
} from '../models/ActivityProgress';
import { DndOrdenationActivityProgress } from '@/models/DNDOrdenation';

export const answersActivitiesType = [
  ActivityTypeEnum.TRUE_OR_FALSE,
  ActivityTypeEnum.QUIZ,
  ActivityTypeEnum.DND_ORDENATION,
  ActivityTypeEnum.DND_POSITIONAL,
];

type getAnswerByTypeReturn = AttemptAnswer | undefined;

export const getAnswerByType = ({
  type,
  data,
  ...content
}: any): getAnswerByTypeReturn => {
  if (!questionHasAnswer(type) || !('answers' in content)) {
    return undefined;
  } else {
    const { answers } = content;
    switch (type) {
      case ActivityTypeEnum.TRUE_OR_FALSE:
        return answers;
      case ActivityTypeEnum.QUIZ:
        const answer = data.quizAnswers.findIndex(
          ({ answerVerifier }: any) => answers.answer === answerVerifier,
        );
        return {
          ...answers,
          answer,
        };
      case ActivityTypeEnum.DND_ORDENATION:
        return answers;
      case ActivityTypeEnum.DND_POSITIONAL:
        return answers;

      default:
        return undefined;
    }
  }
};

export const questionHasAnswer = (type: ActivityTypeEnum) =>
  answersActivitiesType.includes(type);

export const stringAnswer = (answer: number | boolean | null) => {
  if (answer === -1 || answer === null) {
    return '-';
  } else if (typeof answer === 'boolean') {
    return i18n.t(`lesson.activity.${answer ? 'true' : 'false'}`);
  } else {
    return String.fromCharCode(answer + 65);
  }
};

type QuizProgressParam = {
  type: ActivityTypeEnum.QUIZ;
  questionProgress: QuizActivityProgress;
};

type TrueOrFalseProgressParam = {
  type: ActivityTypeEnum.TRUE_OR_FALSE;
  questionProgress: TrueOrFalseActivityProgress;
};

type DndPositionalProgressParam = {
  type: ActivityTypeEnum.DND_POSITIONAL;
  questionProgress: DNDPositionalActivityProgress;
};

type DndOrgenationProgressParam = {
  type: ActivityTypeEnum.DND_ORDENATION;
  questionProgress: DndOrdenationActivityProgress;
};

type TextProgressParam = {
  type: ActivityTypeEnum.TEXT;
  questionProgress: TextActivityProgress;
};

type VideoProgressParam = {
  type: ActivityTypeEnum.VIDEO;
  questionProgress: VideoActivityProgress;
};

type CodeProgressParam = {
  type: ActivityTypeEnum.CODE;
  questionProgress: IQuestionProgress;
};

type Param =
  | QuizProgressParam
  | TrueOrFalseProgressParam
  | DndPositionalProgressParam
  | DndOrgenationProgressParam
  | TextProgressParam
  | VideoProgressParam
  | CodeProgressParam;

export function createQuestionProgress({
  questionProgress,
  type,
}: Param): IQuestionProgress {
  if (type === ActivityTypeEnum.CODE) {
    throw new Error('Progress not implemented');
  }
  const updatedQuestionProgress = {
    isCurrentQuestion: questionProgress.isCurrentQuestion,
    isDone: questionProgress.isDone,
    progressId: questionProgress.id,
    answer: 'answer' in questionProgress ? questionProgress.answer : undefined,

    type,
  };
  if (type === ActivityTypeEnum.QUIZ) {
    return {
      ...updatedQuestionProgress,
      order: questionProgress.quizActivity.order,
      questionId: questionProgress.quizActivity.id,
    };
  }
  if (type === ActivityTypeEnum.DND_POSITIONAL) {
    return {
      ...updatedQuestionProgress,
      questionId: questionProgress.dndPositionalActivity.id,
      order: questionProgress.dndPositionalActivity.order,
    };
  }
  if (type === ActivityTypeEnum.DND_ORDENATION) {
    return {
      ...updatedQuestionProgress,
      questionId: questionProgress.dndOrdenationActivity.id,
      order: questionProgress.dndOrdenationActivity.order,
    };
  }
  if (type === ActivityTypeEnum.TEXT) {
    return {
      ...updatedQuestionProgress,
      questionId: questionProgress.textActivity.id,
      order: questionProgress.textActivity.order,
    };
  }
  if (type === ActivityTypeEnum.VIDEO) {
    return {
      ...updatedQuestionProgress,
      questionId: questionProgress.videoActivity.id,
      order: questionProgress.videoActivity.order,
    };
  }
  return {
    ...updatedQuestionProgress,
    questionId: questionProgress.trueOrFalseActivity.id,
    order: questionProgress.trueOrFalseActivity.order,
  };
}
