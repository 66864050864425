import parse from 'html-react-parser';
import { twMerge } from 'tailwind-merge';
import { uniqueId } from 'lodash';

import { HtmlPreviewErrorDispatcher } from '@/errors/htmlPreview';
import { ErrorCapturer } from '@/adapters/ErrorCapturer';

export type DisplayFormat = 'book' | 'default' | 'none';

interface HtmlPreviewProps {
  testId?: string;
  html?: string;
  format?: DisplayFormat;
  className?: string;
}

export const formatClasses: Record<DisplayFormat, string> = {
  book: 'bookHtmlDisplay',
  default: 'defaultHtmlDisplay',
  none: '',
};

export const HtmlPreview = ({
  html: content = '',
  format = 'default',
  className,
  testId,
}: HtmlPreviewProps) => {
  const replaceHtml = (rawHtml: string): string => {
    try {
      const linkPattern = /<a href="https?:\/\//gi;
      const linkReplacement = '<a target="_blank" href="https://';

      const youtubePatterns = [
        /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})&amp;ab_channel=[\w%]*"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/youtu\.be\/([\w-]{11})\?t=[\w-]*"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})&amp;t=[\w-]*"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([\w-]{11})"><\/oembed>/gi,
        /<oembed\s+url="https:\/\/youtu\.be\/([\w-]{11})"><\/oembed>/gi,
        /<oembed\s+url="(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)&?(?:[^"]+)?"><\/oembed>/gi,
        /<oembed\s+url="(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)&?(?:list=[\w-]+&?)?(?:[^"]+)?"><\/oembed>/gi,
      ];

      const replaceOembedWithIframe = (match: string, videoId: string) => {
        return `<iframe class="videoHtmlDisplay" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
      };

      const colorPatterns = [
        /color:\s*rgb\(0,\s*0,\s*0\)/gi,
        /color:\s*hsl\(0,\s*0%,\s*0%\)/gi,
        /color:#000000/gi,
      ];
      const colorReplacement = 'color: var(--base-content)';

      let replacedHtml = rawHtml.replace(linkPattern, linkReplacement);

      youtubePatterns.forEach(pattern => {
        replacedHtml = replacedHtml.replace(pattern, replaceOembedWithIframe);
      });

      colorPatterns.forEach(pattern => {
        replacedHtml = replacedHtml.replace(pattern, colorReplacement);
      });

      return replacedHtml;
    } catch (error: any) {
      const htmlPreviewError = new HtmlPreviewErrorDispatcher(error);

      const errorCapturer = new ErrorCapturer(htmlPreviewError);
      errorCapturer.dispatchError();

      return '';
    }
  };

  if (content)
    return (
      <div
        data-testid={testId}
        className={twMerge(
          'font-rubik text-wrap break-words htmlDisplay ck-content',
          formatClasses[format],
          className,
        )}
      >
        {parse(replaceHtml(content), {
          transform(reactNode) {
            return (
              <span key={uniqueId()} className="block">
                {reactNode}
              </span>
            );
          },
        })}
      </div>
    );

  return null;
};
