import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'moment-timezone';
import 'moment/locale/pt-br';

import './App.css';
import DashboardPage from './pages/dashboard/DashboardPage';
import pt_BR from './translations/pt_BR';
import en from './translations/en';
import { initGA } from './utils/analytics';
import { useEffect } from 'react';

i18next.use(initReactI18next).init({
  lng: 'pt_BR',
  fallbackLng: 'pt_BR',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    pt_BR: { translation: pt_BR },
    en: { translation: en },
  },
});

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') initGA();
  }, []);

  return <DashboardPage />;
}

export default App;
