import { ReplyIcon, ViewGridIcon } from '@heroicons/react/outline';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConditionalRenderer from './ConditionalRenderer';
import MainButton from './buttons/MainButton';
import Text from './dataDisplay/Text';

export interface ErrorActionButton {
  text: string;
  icon: JSX.Element;
  action?(): void;
  href?: string;
}

interface ErrorProps {
  statusCode?: number;
  errorTextTitle?: string;
  errorTextSubTitle?: string;
  testId?: string;
  actionButtons?: ErrorActionButton[];
  hideStatusCode?: boolean;
  animation?: {
    src: string;
    className: string;
  };
}

export default function ErrorComponent(props: ErrorProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'errors.errorPage',
  });

  const { pathname } = useLocation();
  const defaultActionButton: ErrorActionButton = {
    icon: <ViewGridIcon />,
    text: t('panelButton'),
    href: '/',
  };
  const { statusCode, errorTextTitle, errorTextSubTitle, testId } = props;
  const {
    hideStatusCode = false,
    animation = {
      className: 'w-80',
      src: '/images/error-animation.json',
    },
  } = props;

  const errorDefaultText = {
    400: {
      title: t('badRequest.title'),
      subTitle: t('badRequest.subtitle'),
    },
    403: {
      title: t('forbidden.title'),
      subTitle: t('forbidden.subtitle'),
    },
    404: {
      title: t('notFound.title'),
      subTitle: t('notFound.subtitle'),
    },
    500: {
      title: t('internError.title'),
      subTitle: t('internError.subtitle'),
    },
  };

  const { title, subTitle } =
    errorDefaultText[statusCode as keyof typeof errorDefaultText] ||
    errorDefaultText[404];

  const actionButtons = props.actionButtons || [defaultActionButton];

  const navigate = useNavigate();

  const backButtonAction = () => {
    const isHome = pathname === '/';
    if (isHome) {
      navigate('/login');
      return;
    }
    navigate(-1);
  };

  return (
    <div
      data-testid={testId}
      className="flex flex-col grow-0 justify-start lg:items-start items-center lg:gap-16  gap-5 pt-4 pb-24"
    >
      <ConditionalRenderer condition={!hideStatusCode}>
        <div className="flex flex-col grow justify-start items-start gap-1.5 p-0 h-6 bg-base-200">
          <Text
            format="poppins-600"
            text={statusCode || '404'}
            className="text-primary w-12 h-6 grow-0 text-2xl"
          />
        </div>
      </ConditionalRenderer>
      <div className="gap-7 grow-0 flex flex-col justify-center items-center self-stretch px-9 py-0">
        <div className="grow-0 flex flex-col justify-start gap-16 p-0">
          <div className=" grow-0 flex flex-col justify-start md:gap-3.5 lg:gap-3.5 gap-14 ">
            <Text
              format="poppins-600"
              text={errorTextTitle || title}
              className="h-7 grow-0 leading-none tracking-normal lg:text-left not-italic text-center text-primary text-2xl"
            />

            <Text
              text={errorTextSubTitle || subTitle}
              className="h-4 grow-0  leading-none tracking-normal not-italic lg:text-left  text-center"
            />
          </div>
          <div className="h-7 grow-0 flex flex-row justify-center lg:justify-start items-start gap-2.5 p-0">
            <MainButton
              icon={<ReplyIcon />}
              id="text-activity-save"
              text={t('backButton')}
              onClick={backButtonAction}
              className="rounded-md"
            />
            {actionButtons.map(actionButton => (
              <MainButton
                key={actionButton.text}
                icon={actionButton.icon}
                text={actionButton.text}
                type="button"
                color="custom"
                className="text-primary bg-base-100 px-2.5 py-1.5 rounded-md"
                onClick={actionButton.action}
                href={actionButton.href}
              />
            ))}
          </div>
          <Player
            className={animation.className}
            autoplay
            loop
            src={animation.src}
          />
        </div>
      </div>
    </div>
  );
}
