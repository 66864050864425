import { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { DayOfWeek } from 'react-day-picker';
import { ExclamationIcon } from '@heroicons/react/outline';
import { isEqual } from 'lodash';

import alert from '@/utils/UseAlert';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Klass, {
  DaysOfWeekTypeEnum,
  KlassPayload,
  KlassStatusEnum,
  KlassTypeEnum,
} from '@/models/Klass';
import { buildChangedObject } from '@/utils/buildChangedObject';
import TimeInput from '@/components/common/dataInput/TimeInput';
import { CourseDurationMinuteEnum } from '@/models/Course';
import { courseDurationNumberForString } from '@/utils/CourseDuration';
import {
  createKlass,
  updateKlass,
  updateKlassStatus,
} from '@/data/services/klassServices';
import DateInput from '@/components/common/dataInput/DateInput';
import { handleUserFullName } from '@/functions/handleUserFullName';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { UserTypeEnum } from '@/models/User';
import TooltipHandler from '@/components/common/TooltipHandler';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import {
  courseBasesQueryKeys,
  klassesQueryKeys,
  unitsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import Text from '@/components/common/dataDisplay/Text';
import ComponentGuard from '@/components/common/ComponentGuard';
import NumberInput from '@/components/common/dataInput/NumberInput';
import EnumInput from '@/components/common/dataInput/EnumInput';
import AccordionContainer from '@/components/common/cards/AccordionContainer';
import { getErrorMessage } from '@/utils/getErrorMessage';

export interface KlassFormProps {
  klass?: Klass;
  disabled?: boolean;
  className?: string;
  formMode?: 'edit' | 'create';
  onUpdate?: (klass?: Klass) => void;
  onCancel?: () => void;
}

export default function KlassForm(props: KlassFormProps) {
  const {
    klass,
    disabled,
    className,
    onUpdate,
    onCancel,
    formMode = 'edit',
  } = props;

  const [hasChanges, setHasChanges] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageKlasses.addKlass',
  });

  const { t: tStatus } = useTranslation('translation', {
    keyPrefix: 'klassStatus',
  });

  const { t: tCategory } = useTranslation('translation', {
    keyPrefix: 'courseCategory',
  });

  const { t: tWeekdays } = useTranslation('translation', {
    keyPrefix: 'weekday',
  });

  const { t: tKlassType } = useTranslation('translation', {
    keyPrefix: 'klassType',
  });

  const defaultDayTimes = [{}];
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm<KlassPayload>({
    defaultValues: {
      dayTimes: klass?.dayTimes ?? defaultDayTimes,
      duration: klass?.duration,
      status: klass?.status,
      unitId: klass?.unitId ?? 0,
      maxCapacity: klass?.maxCapacity ?? 10,
      conectaSeats: klass?.conectaSeats,
      klassType: klass?.klassType,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'dayTimes',
  });

  const queryClient = useQueryClient();

  const blockChanges = !isEmpty(errors);

  const onEdit = async ({
    status,
    duration,
    dayTimes,
    klassStartDate,
    ...rest
  }: KlassPayload) => {
    if (!klass) throw new Error('Klass not found');

    const previousDate = moment(klass.klassStartDate).format('YYYY-MM-DD');
    const dayTimesFormated = dayTimes?.map(({ time, day }) => ({
      day,
      time: moment(`${previousDate} ${time}`).format('HH:mm:ss'),
    }));

    const parcialKlass = buildChangedObject<Klass>(klass, {
      duration: Number(duration),
      dayTimes: dayTimesFormated,
      ...rest,
    });

    if (Object.keys(parcialKlass).length)
      return await updateKlass(klass.id, parcialKlass);

    if (status && klass.status !== status)
      return await updateKlassStatus(klass.id, { status });
  };

  const onCreate = async ({
    unitId,
    dayTimes = [],
    duration,
    teacherId,
    klassStartDate,
    klassType,
    conectaSeats,
    maxCapacity,
    courseId,
  }: KlassPayload) => {
    const firstTime = dayTimes[0]?.time;

    const previousDate = moment(klassStartDate).format('YYYY-MM-DD');

    const timeFormat =
      firstTime && moment(`${previousDate} ${firstTime}`).format('HH:mm:ss');

    klassStartDate = moment(`${previousDate} ${timeFormat}`).format(
      'YYYY-MM-DDTHH:mm:ss',
    );

    const newklass: KlassPayload = {
      unitId,
      courseId,
      teacherId,
      duration: Number(duration),
      dayTimes,
      klassStartDate,
      maxCapacity,
      klassType,
      conectaSeats,
    };

    await createKlass(newklass);
  };

  const klassesQueryKey = klassesQueryKeys.list._def;

  const { mutate: update, isLoading: isUpdating } = useMutation(onEdit, {
    onSuccess: async data => {
      setHasChanges(false);
      alert.success(t('saveSuccess'));
      await queryClient.invalidateQueries(klassesQueryKey);
      onCancel?.();
      onUpdate?.(data);
    },
    onError: error => {
      alert.error(getErrorMessage(error));
    },
  });

  const { mutate: create, isLoading: isCreating } = useMutation(onCreate, {
    onSuccess: async () => {
      setHasChanges(false);
      alert.success(t('addSuccess'));
      await queryClient.invalidateQueries(klassesQueryKey);
      onCancel?.();
    },
    onError: error => {
      alert.error(getErrorMessage(error));
    },
  });

  const onSubmit = async (data: KlassPayload) => {
    formMode === 'create' ? create(data) : update(data);

    setHasChanges(false);
  };

  const onReset = () => {
    reset({
      dayTimes: klass?.dayTimes,
      duration: klass?.duration,
      status: klass?.status,
      klassType: klass?.klassType,
      maxCapacity: klass?.maxCapacity,
      conectaSeats: klass?.conectaSeats,
    });
    onCancel?.();
    setHasChanges(false);
  };

  useEffect(() => {
    const subscribe = watch(value => setHasChanges(!isEqual(value, klass)));
    return () => {
      setHasChanges(false);
      subscribe.unsubscribe();
    };
  }, [klass, watch]);

  const unitId = watch('unitId');

  const { data: unit, isInitialLoading: loadingCalendar } = useQuery({
    ...unitsQueryKeys.get(unitId ?? 0),
    enabled: !!unitId && formMode === 'create',
  });

  const recessCalendar = unit?.recess;

  const weekDaysValues = {
    [DaysOfWeekTypeEnum.SUNDAY]: 0,
    [DaysOfWeekTypeEnum.MONDAY]: 1,
    [DaysOfWeekTypeEnum.TUESDAY]: 2,
    [DaysOfWeekTypeEnum.WEDNESDAY]: 3,
    [DaysOfWeekTypeEnum.THURSDAY]: 4,
    [DaysOfWeekTypeEnum.FRIDAY]: 5,
    [DaysOfWeekTypeEnum.SATURDAY]: 6,
  };

  const weekdays = Object.values(weekDaysValues);

  const recessDates = recessCalendar?.map(date => moment(date).toDate()) ?? [];

  const dayTimes = watch('dayTimes');

  const hasSelectedKlassDayTimes =
    dayTimes?.reduce((prev, { day, time }) => !!day && !!time, false) ?? false;

  const klassWeekDays =
    dayTimes?.filter(value => !!value)?.map(({ day }) => weekDaysValues[day]) ??
    [];

  const weekDayBlocked: DayOfWeek = {
    dayOfWeek: weekdays.filter(value => !klassWeekDays.includes(value)),
  };

  const disableDatesMacther = [weekDayBlocked, ...recessDates];

  const conectaFields =
    watch('klassType') === KlassTypeEnum.CONECTA ||
    klass?.klassType === KlassTypeEnum.CONECTA
      ? 'open'
      : 'closed';

  const validateTime = (value: string) => {
    if (value) {
      const time = moment(value, 'HH:mm');
      if (!time.isValid() || value.includes('-')) return t('error.invalidTime');
    }
    return true;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4 ${className || ''}`}
      data-testid="klassForm"
    >
      <WarningTag />
      <ConditionalRenderer condition={formMode === 'create'}>
        <Controller
          name="unitId"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              displayName={unit => unit.name}
              onSelect={({ id }) => onChange(id)}
              input={{
                label: t('unit'),
                errorLabelText: error?.message,
                labelPosition: 'left',
                disabled,
                testId: 'selectUnit',
                className: { base: 'font-500 text-16' },
              }}
              filters={{
                isActive: true,
              }}
              onDeselect={() => onChange(undefined)}
            />
          )}
          rules={{
            required: formMode === 'create' ? t('error.unit') : undefined,
          }}
        />
      </ConditionalRenderer>
      <div
        data-testid="disabledFields"
        className={`${
          (formMode === 'create' && !unitId) || loadingCalendar
            ? 'disabled'
            : ''
        } flex flex-col gap-4 transition ease-in-out duration-300`}
      >
        <ConditionalRenderer condition={formMode === 'create'}>
          <Controller
            name="courseId"
            control={control}
            render={({ field: { onChange } }) => (
              <InfiniteSearchInput
                service={courseBasesQueryKeys.list}
                displayName={course =>
                  `${course.name} - ${tCategory(course.category)}`
                }
                filters={{ coursePathStatus: [VersioningStatusEnum.PUBLISHED] }}
                onSelect={({ id }) => onChange(id)}
                input={{
                  label: t('course'),
                  errorLabelText: errors?.coursePathId?.message,
                  disabled,
                  labelPosition: 'left',
                  testId: 'selectCourse',
                  className: { base: 'font-500 text-16' },
                }}
                onDeselect={() => onChange(undefined)}
              />
            )}
            rules={{
              required: formMode === 'create' ? t('error.course') : undefined,
            }}
          />
        </ConditionalRenderer>

        {fields.map((field, index) => {
          return (
            <Fragment key={field.id}>
              <Controller
                rules={{ required: t('error.dayWeek') }}
                control={control}
                name={`dayTimes.${index}.day`}
                render={({ field: { onChange, value } }) => (
                  <EnumInput
                    testId="selectDay"
                    disabled={disabled}
                    errorLabelText={errors.dayTimes?.[index]?.day?.message}
                    label={t('day')}
                    labelPosition="left"
                    enumModel={DaysOfWeekTypeEnum}
                    displayName={option =>
                      tWeekdays(option as DaysOfWeekTypeEnum)
                    }
                    onSelect={option => onChange(option)}
                    type="string"
                    onDeselect={() => onChange(null)}
                    selected={value}
                    className={{ base: 'font-500 text-16' }}
                  />
                )}
              />
              <Controller
                name={`dayTimes.${index}.time`}
                control={control}
                rules={{
                  required: t('error.klassTime'),
                  validate: validateTime,
                }}
                render={({ field: { onChange, value } }) => (
                  <TimeInput
                    label={t('time')}
                    testId="timeInput"
                    errorLabelText={errors.dayTimes?.[index]?.time?.message}
                    labelPosition="left"
                    value={value}
                    disabled={disabled}
                    onTimeChange={time => onChange(time)}
                    className={{ base: 'font-500 text-16' }}
                  />
                )}
              />
            </Fragment>
          );
        })}

        <ConditionalRenderer condition={formMode === 'create'}>
          <TooltipHandler
            renderTooltip={!hasSelectedKlassDayTimes}
            tooltipMessage={t('selectDayTimes')}
            classNameContainer="w-full"
          >
            <Controller
              control={control}
              rules={{
                required: t('error.klassDate'),
              }}
              name="klassStartDate"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  disabledDates={disableDatesMacther}
                  label={t('startDate')}
                  labelPosition="left"
                  testId="startDate"
                  value={value}
                  className={{
                    input: 'disabled:border-primary disabled:opacity-40',
                    base: 'font-500 text-16',
                  }}
                  onDateChange={date => onChange(date)}
                  errorLabelText={errors.klassStartDate?.message}
                  disabled={disabled || !hasSelectedKlassDayTimes}
                />
              )}
            />
          </TooltipHandler>
        </ConditionalRenderer>

        <Controller
          rules={{ required: t('error.duration') }}
          control={control}
          name="duration"
          render={({ field: { onChange, value } }) => (
            <EnumInput
              testId="selectDuration"
              disabled={disabled}
              errorLabelText={errors.duration?.message}
              label={t('duration')}
              labelPosition="left"
              enumModel={CourseDurationMinuteEnum}
              displayName={option =>
                courseDurationNumberForString(option as number)
              }
              onSelect={option => onChange(option)}
              type="numeric"
              onDeselect={() => onChange(null)}
              selected={value}
              className={{ base: 'font-500 text-16' }}
            />
          )}
        />

        <ConditionalRenderer condition={formMode === 'create'}>
          <Controller
            name="teacherId"
            control={control}
            render={({ field: { onChange } }) => (
              <InfiniteSearchInput
                service={usersQueryKeys.list}
                options={{ enabled: !!unitId }}
                filters={{
                  unit: unitId ? [unitId] : undefined,
                  userType: [UserTypeEnum.TEACHER],
                }}
                displayName={teacher => handleUserFullName(teacher)}
                onSelect={({ id }) => onChange(id)}
                className={{
                  input: 'w-full rounded-md placeholder:font-400 max-w-2xl',
                }}
                input={{
                  label: t('teacher'),
                  errorLabelText: errors?.teacherId?.message,
                  labelPosition: 'left',
                  disabled,
                  testId: 'selectTeacher',
                  className: { base: 'font-500 text-16' },
                }}
                onDeselect={() => onChange(undefined)}
              />
            )}
            rules={{
              required: formMode === 'create' ? t('error.teacher') : undefined,
            }}
          />
        </ConditionalRenderer>

        <ConditionalRenderer condition={formMode === 'edit'}>
          <Controller
            rules={{
              required: t('error.klassStatus'),
              validate: value => (!value ? t('error.klassStatus') : true),
            }}
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <EnumInput
                testId="selectStatus"
                disabled={disabled}
                errorLabelText={errors.status?.message}
                label={t('status')}
                labelPosition="left"
                enumModel={KlassStatusEnum}
                displayName={option => tStatus(option as KlassStatusEnum)}
                onSelect={option => onChange(option)}
                type="string"
                onDeselect={() => onChange(null)}
                selected={value}
                className={{ base: 'font-500 text-16' }}
              />
            )}
          />
        </ConditionalRenderer>

        <Controller
          rules={{
            required: t('error.klassType'),
          }}
          control={control}
          name="klassType"
          render={({ field: { onChange, value } }) => (
            <EnumInput
              testId="selectType"
              disabled={disabled || formMode === 'edit'}
              errorLabelText={errors.klassType?.message}
              label={t('klassType')}
              labelPosition="left"
              enumModel={KlassTypeEnum}
              displayName={option => tKlassType(option)}
              onSelect={option => {
                if (option === KlassTypeEnum.REGULAR)
                  setValue('conectaSeats', 0);
                onChange(option);
              }}
              type="string"
              onDeselect={() => onChange(null)}
              selected={value}
              className={{ base: 'font-500 text-16' }}
            />
          )}
        />

        <AccordionContainer
          initial={conectaFields}
          animate={conectaFields}
          className="flex flex-col gap-4"
        >
          <Controller
            name="maxCapacity"
            control={control}
            render={({ field: { onChange, value = 0 } }) => (
              <NumberInput
                testId="maxCapacity"
                disabled={disabled}
                label={t('maxCapacity')}
                onChange={onChange}
                labelPosition="left"
                className={{
                  base: 'font-500 text-16',
                  label: 'p-0 text-16',
                  input: 'gap-3 w-16',
                }}
                showDecButton
                showIncButton
                min={0}
                value={value}
              />
            )}
          />

          <Controller
            name="conectaSeats"
            control={control}
            render={({ field: { onChange, value = 0 } }) => (
              <NumberInput
                testId="conectaSeats"
                disabled={disabled}
                label={t('conectaSeats')}
                onChange={onChange}
                labelPosition="left"
                className={{
                  base: 'font-500 text-16',
                  label: 'p-0 text-16',
                  input: 'gap-3 w-16',
                }}
                showDecButton
                showIncButton
                min={0}
                value={value}
              />
            )}
          />
        </AccordionContainer>
      </div>

      <ConditionalRenderer condition={!disabled}>
        <SaveCancelGroup
          loading={isUpdating || isCreating}
          save={{
            disabled: blockChanges || !hasChanges,
            testId: 'saveButton',
          }}
          cancel={{
            onClick: onReset,
            testId: 'cancelButton',
            disabled: isUpdating,
          }}
        />
      </ConditionalRenderer>
    </form>
  );
}

function WarningTag() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'warning',
  });

  return (
    <ComponentGuard roles={[UserTypeEnum.UNIT_ADMIN, UserTypeEnum.TEACHER]}>
      <aside
        role="alert"
        className="alert bg-warning-content text-warning font-400"
      >
        <ExclamationIcon className="w-6 h-6" />
        <Text text={t('addAndEditClass')} className="text-left leading-tight" />
      </aside>
    </ComponentGuard>
  );
}
