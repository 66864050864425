import Chapter from './Chapter';
import Tool from './Tool';

export type bookType = 'STUDENT' | 'TEACHER';

export default interface Book {
  id: number;
  filename: string;
  uploadedAt: string;
  chapters: Chapter[];
  verion: number;
  status: BookStatusEnum;
  bookType: bookType;
  tools: Tool[];
}

export enum BookStatusEnum {
  PUBLISHED = 'PUBLISHED',
}
