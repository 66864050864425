import { EnrollmentStatusEnum } from './Enrollment';

export default class ScheduledLessonReport {
  id!: number;
  scheduledLesson!: number;
  student!: number;
  presence: PresenceEnum | null = null;
  effort: GradeEnum | null = null;
  challenge: GradeEnum | null = null;
  behavior: GradeEnum | null = null;
  notes: string | null = null;
  status!: ScheduledLessonReportEnum;
  presenceReward: number | null = null;

  constructor(init?: Partial<ScheduledLessonReport>) {
    Object.assign(this, init);
  }
}

export enum ScheduledLessonReportEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  TRANSFERED = 'TRANSFERED',
  NOT_ENROLLED = 'NOT_ENROLLED',
  NONE = 'NONE',
}

export enum PresenceEnum {
  ATTENDED = 'ATTENDED',
  MISSED = 'MISSED',
  RESCHEDULED = 'RESCHEDULED',
  NONE = 'NONE',
}

export enum GradeEnum {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum ContentGivenEnum {
  GAVE_UP_T0_50_PERCENT = 'I_GAVE_UP_TO_50%_OF_THE_CONTENT',
  GAVE_BETWEEN_50_99_PERCENT = 'I_GAVE_BETWEEN_50-99%_OF_THE_CONTENT',
  GAVE_100_PERCENT = 'I_GAVE_100%_OF_THE_CONTENT',
  GAVE_100_PERCENT_AND_MORE = 'I_GAVE_ALL_THE_CONTENT_AND_MORE',
}

export enum ActiveEnum {
  ACTIVE_IN_THIS_LESSON = 'ACTIVE_IN_THIS_LESSON',
}

export enum TranferredEnum {
  TRANSFERRED_FROM_CLASS = 'TRANSFERRED_FROM_CLASS',
  TRANSFERRED_TO_CLASS = 'TRANSFERRED_TO_CLASS',
}

export type EnrollmentAndReportStatus =
  | EnrollmentStatusEnum
  | TranferredEnum
  | ActiveEnum;
