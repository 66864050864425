import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Text from '@/components/common/dataDisplay/Text';
import ErrorComponent from '@/components/common/ErrorComponent';
import InfinityList from '@/components/common/InfinityList';
import Skeleton from '@/components/common/Skeleton';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useListService from '@/data/hook/useListService';
import {
  enrollmentsQueryKeys,
  klassesProgressesQueryKeys,
  scheduledLessonsQueryKeys,
  usersQueryKeys,
} from '@/data/services/querykeys';
import {
  activeEnrollmentStatus,
  inactiveEnrollmentStatus,
} from '@/functions/enrollment';
import Enrollment from '@/models/Enrollment';
import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import User from '@/models/User';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import KlassesInfoCol from './KlassesInfoCol';
import KlassesLessonCol from './KlassesLessonCol';

interface KlassProgressTableProps {
  klass?: Klass;
  isLoading?: boolean;
  error: any;
}

export default function TableContent(props: KlassProgressTableProps) {
  const { klass, isLoading, error: klassError } = props;

  const { klassId: klassIdParam } = useParams();

  const klassId = Number(klassIdParam);

  const defaultQueryOptionEnable = {
    enabled: !isNaN(klassId),
    staleTime: REQUEST_STALE_TIME_IN_MS,
  };

  const [isOnlyActive, setIsOnlyActive] = useState(true);

  const enrollStatus = isOnlyActive
    ? activeEnrollmentStatus
    : inactiveEnrollmentStatus;

  const { data: klassProgress } = useQuery({
    ...defaultQueryOptionEnable,
    ...klassesProgressesQueryKeys.get(klassId),
  });

  const { data: teacher } = useQuery({
    ...usersQueryKeys.get(klass?.teacherId ?? 0),
    enabled: !!klass?.teacherId,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const {
    results: enrollments,
    error: enrollmentError,
    isInitialLoading: isLoadingEnrollments,
  } = useListService({
    ...defaultQueryOptionEnable,
    ...enrollmentsQueryKeys.list({
      klassId,
      current: true,
      status: enrollStatus,
    }),
  });

  const {
    results: scheduledLessonList,
    isInitialLoading: isLoadingLesson,
    error: lessonsError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list({
      klassId: klass?.id,
      isActive: true,
      pageSize: 10,
    })._ctx.infinity,
    ...defaultQueryOptionEnable,
  });

  const error = klassError || enrollmentError || lessonsError;

  const { t: tPage } = useTranslation('translation', {
    keyPrefix: 'klassProgressPage',
  });

  if (isLoading) {
    return (
      <div className="flex gap-1.5">
        <Skeleton className="bg-primary-content rounded-2xl w-56 h-96" />
        <Skeleton className="bg-primary-content rounded-2xl w-full h-96" />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorComponent
        testId="errorComponent"
        statusCode={error?.response?.status}
        errorTextTitle={
          error?.response?.status === 404
            ? tPage('notFoundTitle')
            : tPage('genericErrorTitle')
        }
        errorTextSubTitle={
          error?.response?.status === 404
            ? tPage('notFoundText')
            : tPage('genericErrorText')
        }
      />
    );
  }

  return (
    <TableComponent
      teacher={teacher}
      enrollments={enrollments}
      klass={klass}
      klassProgress={klassProgress?.progress || 0}
      isFetchingNextPage={isFetchingNextPage}
      isLoadingEnrollments={isLoadingEnrollments}
      scheduledLessonList={scheduledLessonList}
      isOnlyActive={isOnlyActive}
      setIsOnlyActive={setIsOnlyActive}
      isLoadingLesson={isLoadingLesson}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
}

type TableComponentProps = {
  teacher?: User;
  enrollments: Enrollment[];
  klass?: Klass;
  klassProgress: number;
  isLoadingEnrollments: boolean;
  isLoadingLesson: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  scheduledLessonList: ScheduledLesson[];
  isOnlyActive: boolean;
  setIsOnlyActive: Dispatch<SetStateAction<boolean>>;
};

const TableComponent = ({
  teacher,
  enrollments,
  klass,
  klassProgress,
  isLoadingEnrollments,
  isLoadingLesson,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  scheduledLessonList,
  isOnlyActive,
  setIsOnlyActive,
}: TableComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-auto flex gap-1.5 relative">
      <div className="flex absolute top-16 left-2 text-14 items-center gap-2 w-fit">
        <input
          type="checkbox"
          defaultChecked={isOnlyActive}
          onChange={() => setIsOnlyActive(old => !old)}
          className="toggle toggle-sm toggle-primary mx-2"
        />
        <Text
          text={
            isOnlyActive
              ? t('klassProgressPage.active')
              : t('klassProgressPage.inactive')
          }
        />
      </div>
      <KlassesInfoCol
        enrollments={enrollments}
        klassId={klass?.id || 0}
        klassProgress={klassProgress || 0}
        isLoading={isLoadingEnrollments}
        isOnlyActive={isOnlyActive}
      />
      <ConditionalRenderer
        condition={!isLoadingLesson}
        fallback={
          <Skeleton className="bg-primary-content rounded-2xl w-full h-auto" />
        }
      >
        <InfinityList
          className="flex flex-row h-full w-fit border-collapse py-3.5 scrollbar-thin 
          scrollbar-thumb-primary/40 scrollbar-track-primary-content scrollbar-thumb-rounded-full 
          scrollbar-track-rounded-full overflow-x-scroll rounded-2xl border border-neutral-content bg-base-100"
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          onReachEnd={fetchNextPage}
        >
          {scheduledLessonList.map(scheduledLesson => {
            return (
              <KlassesLessonCol
                teacher={teacher}
                key={scheduledLesson.id}
                klass={klass}
                enrollments={enrollments}
                scheduledLesson={scheduledLesson}
              />
            );
          })}
        </InfinityList>
      </ConditionalRenderer>
    </div>
  );
};
