import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import OutlineButton from '@/components/common/buttons/OutlineButton';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
} from '@heroicons/react/outline';
import useAuth from '@/data/hook/useAuth';
import { UserTypeEnum } from '@/models/User';
import NoAdminScreenForMobile from '../admin/NoAdminScreenForMobile';
import ScheduledLesson from '@/models/ScheduledLesson';
import { subDays } from 'date-fns';
import { DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Text from '@/components/common/dataDisplay/Text';
import IconButton from '@/components/common/buttons/IconButton';
import ManageScheduledLesson from '@/components/admin/calendar/ManageScheduledLesson/ManageScheduledLesson';
import Skeleton from '@/components/common/Skeleton';
import i18next from 'i18next';
import DateRangePicker from '@/components/common/dataInput/DateRangePicker';
import Card from '@/components/common/Card';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import Unit from '@/models/Unit';
import useStatisticService from '@/data/hook/useStatistics';
import InfinityList from '@/components/common/InfinityList';
import { ScheduledLessonFilters } from '@/data/services/scheduledLessonsServices';
import attendancePercentage from '@/utils/statistics/attendencePercentage';
import PageTitle from '@/components/common/PageTitle';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { formatDateRange } from '@/utils/formatDateRange';
import {
  scheduledLessonStatisticsQueryKeys,
  scheduledLessonsQueryKeys,
  unitsQueryKeys,
} from '@/data/services/querykeys';
import useInfiniteService from '@/data/hook/useInfiniteService';

export enum FilterEnum {
  ALL = 'ALL',
  COMPLETED = 'COMPLETED',
  PLANNED = 'PLANNED',
}

const nowDate = new Date(Date.now());

export default function AdminCalendarView() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin',
  });
  const { user } = useAuth();
  const dateNow = new Date(Date.now());
  const defaultSelected: DateRange = {
    from: subDays(nowDate, 29),
    to: nowDate,
  };
  const [selectedFilter, setSelectedFilter] = useState<FilterEnum>(
    FilterEnum.ALL,
  );
  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const [filterDate, setFilterDate] = useState<Date>(dateNow);
  const [range, setRange] = useState<DateRange>(defaultSelected);

  const isMultipleUnit = user && user.unitsIds?.length > 1;

  const startingUnits = getAuthorizedUnits(user);

  function formatDate(date: string) {
    if (i18next.language === 'pt_BR')
      return moment(date).format('dddd, DD [de] MMMM');
    else return moment(date).format('dddd, MMMM Do');
  }

  const filterScheduledLessons = (filter: FilterEnum) => {
    switch (filter) {
      case FilterEnum.COMPLETED:
        return true;
      case FilterEnum.PLANNED:
        return false;
      default:
        return undefined;
    }
  };

  const { from: dateAfter, to: dateBefore } = formatDateRange(range);

  const statisticsFilters = {
    dateAfter,
    dateBefore,
    unitId: selectedUnit?.id ? [selectedUnit.id] : startingUnits,
  };

  const {
    statistic: statisticsCardsData,
    isLoading: isLoadingLessonsStatistics,
  } = useStatisticService({
    enabled: !!range?.from && !!range?.to,
    ...scheduledLessonStatisticsQueryKeys.list(statisticsFilters),
  });

  const lessonFilters: ScheduledLessonFilters = {
    unit: selectedUnit?.id ? [selectedUnit.id] : startingUnits,
    hasDone: filterScheduledLessons(selectedFilter),
    date: moment(filterDate).format('YYYY-MM-DD'),
    isActive: true,
    pageSize: 10,
  };

  const {
    results: scheduledLessons,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading: isLoadingLessons,
    fetchNextPage,
    invalidate: refreshScheduledLessons,
  } = useInfiniteService({
    ...scheduledLessonsQueryKeys.list(lessonFilters)._ctx.infinity,
  });

  const filterControls = [
    { text: t('filters.allLessons'), id: FilterEnum.ALL },
    { text: t('filters.completedLessons'), id: FilterEnum.COMPLETED },
    { text: t('filters.plannedLessons'), id: FilterEnum.PLANNED },
  ];

  const statisticCard = [
    {
      title: t('statisticCard.classes'),
      data: `${statisticsCardsData?.hasDoneLessonsCount || 0}/${
        statisticsCardsData?.lessonsCount || 0
      }`,
      metadata: t('subtitleCard.classesCompleted'),
    },
    {
      title: t('statisticCard.frequency'),
      data: `${attendancePercentage({
        presences: statisticsCardsData?.presencesCount ?? 0,
        totalReports:
          (statisticsCardsData?.presencesCount ?? 0) +
          (statisticsCardsData?.absencesCount ?? 0),
      })}%`,
      metadata: t('subtitleCard.studentFrequency'),
    },
  ];

  return (
    <React.Fragment>
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>
      <div className="hidden md:block">
        <PageTitle headingText={t('calendar')} position="mb-2" />
        <DateRangePicker
          className="text-secondary mb-4"
          range={range}
          setRange={setRange}
        />
        <div className="flex flex-wrap gap-6">
          {statisticCard.map((card, index) => (
            <Card
              key={index}
              className="cursor-default flex-col aspect-video md:w-auto md:h-28 rounded-2xl py-3 gap-y-3 px-6"
            >
              <Text
                format="rubik-500"
                text={card.title}
                className="text-center text-18 text-secondary uppercase"
              />
              <div className="text-center flex flex-col gap-y-2">
                <ConditionalRenderer
                  condition={!isLoadingLessonsStatistics}
                  fallback={
                    <Skeleton className="w-20 rounded-lg h-5 bg-secondary-content self-center" />
                  }
                >
                  <Text
                    text={card.data}
                    className="text-base-content text-18"
                  />
                </ConditionalRenderer>
                <Text text={card.metadata} className="opacity-40 text-14" />
              </div>
            </Card>
          ))}
        </div>
        <div>
          <div className="relative flex items-center text-secondary justify-between">
            <div className="flex items-center gap-x-5">
              <ClockIcon className="w-6 h-6" />
              <Text
                format="rubik-500"
                className="uppercase text-18"
                text={t('nextClasses')}
              />
            </div>
            <ConditionalRenderer
              condition={
                user?.userType === UserTypeEnum.SUPER_ADMIN || isMultipleUnit
              }
            >
              <InfiniteSearchInput
                service={unitsQueryKeys.list}
                filters={{
                  idIn: startingUnits?.toString(),
                  isActive: true,
                }}
                selectedItem={selectedUnit}
                displayName={unit => unit.name}
                onSelect={unit => setSelectedUnit(unit)}
                onDeselect={() => setSelectedUnit(undefined)}
                input={{ testId: 'selectUnit', placeholder: t('allUnits') }}
                className={{ base: 'w-full md:w-80' }}
              />
            </ConditionalRenderer>
          </div>
          <div className="flex items-center gap-x-4 text-primary mt-3">
            <IconButton
              testId="dayBefore"
              id="day-before"
              loading={isLoadingLessons}
              loadingSize="w-5 h-5"
              onClick={() =>
                setFilterDate(moment(filterDate).subtract(1, 'day').toDate())
              }
              icon={<ChevronLeftIcon className="w-5 h-5" />}
            />
            <div className="flex items-center justify-between gap-x-5 leading-none">
              <Text
                text={formatDate(filterDate.toISOString())}
                className="w-fit justify-center text-center capitalize shrink-0"
                format="rubik-500"
              />

              <DatePicker
                onChange={date => {
                  const time = moment(new Date(Date.now())).format('HH:mm');

                  const dateDefault = moment(
                    `${date?.toString().replaceAll('/', '-')} ${time}`,
                  ).toDate();

                  setFilterDate(dateDefault);
                }}
                render={(_value: any, openCalendar: any) => {
                  return (
                    <IconButton
                      testId="calendarButton"
                      className="m-0"
                      onClick={openCalendar}
                      icon={
                        <CalendarIcon
                          className="w-6 h-6 text-primary"
                          strokeWidth={2.1}
                        />
                      }
                    />
                  );
                }}
              />
            </div>
            <IconButton
              testId="nextDay"
              id="next-day"
              loading={isLoadingLessons}
              loadingSize="w-5 h-5"
              onClick={() =>
                setFilterDate(moment(filterDate).add(1, 'day').toDate())
              }
              icon={<ChevronRightIcon className="w-5 h-5" />}
            />
          </div>
          <div className="flex gap-x-3 mt-4">
            {filterControls.map(({ text, id }) => (
              <OutlineButton
                key={id}
                text={text}
                active={selectedFilter === id ? true : false}
                onClick={() => {
                  setSelectedFilter(id);
                }}
              />
            ))}
          </div>
        </div>
        <div
          className="mt-4 flex flex-col gap-y-3"
          id="scheduledLessons-container"
        >
          <ConditionalRenderer
            condition={!isLoadingLessons}
            fallback={
              <Skeleton className="w-full rounded-lg h-20 bg-neutral-content" />
            }
          >
            <RenderScheduledLessons
              scheduledLessons={scheduledLessons ?? []}
              filter={selectedFilter}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              refreshScheudledLessons={refreshScheduledLessons}
            />
          </ConditionalRenderer>
        </div>
      </div>
    </React.Fragment>
  );
}

type RenderScheduledLessonsProps = {
  scheduledLessons: ScheduledLesson[];
  filter: FilterEnum;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  refreshScheudledLessons(): Promise<void>;
};

const RenderScheduledLessons = ({
  scheduledLessons,
  filter,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  refreshScheudledLessons,
}: RenderScheduledLessonsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin',
  });

  if (!scheduledLessons.length) {
    let message: string = t('noContent.allLessons');
    switch (filter) {
      case FilterEnum.PLANNED:
        message = t('noContent.plannedLessons');
        break;
      case FilterEnum.COMPLETED:
        message = t('noContent.completedLessons');
        break;
      default:
        break;
    }
    return (
      <Text
        testId="calendarMessage"
        text={message}
        format="rubik-400"
        className="text-base-content"
      />
    );
  }

  return (
    <InfinityList
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      onReachEnd={fetchNextPage}
      className="gap-2"
    >
      {scheduledLessons.map(lesson => (
        <ManageScheduledLesson
          key={lesson.id}
          updateLessons={refreshScheudledLessons}
          scheduledLesson={lesson}
        />
      ))}
    </InfinityList>
  );
};
