import { twMerge } from 'tailwind-merge';

import useHiddenComponents from '@/data/hook/useHiddenComponent';
import User from '@/models/User';
import HideContainer, {
  HideContainerProps,
} from '@/components/common/HideContainer';
import TeachingWeekHeader from './TeachingWeekHeader';
import TeachingWeekdays from './TeachingWeekdays';
import { useClassScheduleFilters } from '@/data/hook/useClassScheduleFilters';
import { ListKlassesFilters } from '@/data/services/klassServices';
import { KlassStatusEnum } from '@/models/Klass';
import { isSuperAdmin, isTeacher } from '@/functions/auth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';

export type TeachingWeekProps = Pick<
  HideContainerProps,
  'updateRender' | 'render' | 'className' | 'setRef'
> & {
  user: User;
};

export default function TeachingWeek(props: TeachingWeekProps) {
  const { user, className } = props;

  const { components } = useHiddenComponents();

  const component = components['teachingWeek'];

  const initialFilterAdapter = () => {
    let initial: ListKlassesFilters = {
      status: [KlassStatusEnum.IN_PROGRESS],
      ordering: 'klass_start_date',
    };
    if (!isSuperAdmin(user.userType))
      initial = { ...initial, unitId: getAuthorizedUnits(user) };

    if (isTeacher(user.userType)) initial = { ...initial, teacherId: user.id };

    return initial;
  };

  const { updateFilter, updateFilters, events, filters } =
    useClassScheduleFilters<ListKlassesFilters>(initialFilterAdapter());

  return (
    <HideContainer
      {...props}
      icon={component.icon}
      title={component.title}
      type={component.id}
      header={
        <TeachingWeekHeader
          user={user}
          updateFilters={updateFilters}
          events={events}
          updateFilter={updateFilter}
          filters={filters}
        />
      }
      className={{
        header: twMerge(
          'gap-5 p-0 items-start flex-col lg:flex-row',
          className?.header,
        ),
        section: twMerge('flex flex-col gap-3.5 p-4', className?.section),
      }}
    >
      <TeachingWeekdays filters={filters} />
    </HideContainer>
  );
}
